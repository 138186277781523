import { useRef, useState, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  Drawer,
  Grid,
  Paper,
  InputBase,
  useTheme
} from "@material-ui/core";
import { Close, SendRounded, AttachFile } from "@material-ui/icons";
import LoadChatData from "./LoadChatData";
import { useSelector } from "../../../store";
import * as wss  from "../../../utils/wss";

const ChatSection = (props) => {

  const { roomId, identity, chatHistory } = useSelector((state) => {
    return state.room;
  });

  const currentUser = identity;
  const bottomRef = useRef(null);
  const theme = useTheme();
  const [chatData, setChatData] = useState("");
  const [fileUploaded, setFileUploaded] = useState(null);

  useEffect(() => {
    console.log("chat-history-change", chatHistory);
    scrollToBottom();
  }, [chatHistory]);

  const updateFinalChatData = (event) => {
    const tempStruct = {
      roomId: roomId,
      identity: currentUser,
      userId: currentUser,
      text: chatData,
      createdAt: new Date(),
      file: fileUploaded
    }
    wss.sendMessage( tempStruct);
    setChatData("");
    setFileUploaded(null);
  }

  const updateChatData = (event) => {
    setChatData(event.target.value);
  }

  const keyPressed = (e) => {
    if( e.keyCode == 13 ) {
      updateFinalChatData();
      e.preventDefault();
    }
  }

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  const uploadFile = (event) => {
    console.log("event.target.file[0]", event.target.files[0]);
    setFileUploaded(event.target.files[0]);
    setChatData(event.target.files[0].name);
  }

  return (
    <Drawer
    id="chatWindow"
    anchor="right"
    open={props.open}
    onClose={props.closeChatWindow}
  >
    <Box
      sx={{ width: 400, height: '100%' }}
      role="presentation"
    >
      <Grid container spacing={2} sx={{ backgroundColor: "primary.main"}}>
        <Grid item xs={12}>
          <IconButton
            sx={{
            float: "right",
            color: "#ffffff"
            }}
            onClick={props.closeChatWindow}
            >
            <Close />
          </IconButton>
        </Grid>              
      </Grid>              
      <Divider />
      <Box id="chatHistoryContent" sx={{
        height: 'calc(100% - 115px)',
        overflow: 'auto',
        p: 1
      }}>
        <LoadChatData currentUser={currentUser}/>
        <div ref={bottomRef}></div>
      </Box>
      <Paper component="form" sx={{ 
        p: '2px 4px',
        ml: 2,
        display: 'flex',
        alignItems: 'center',
        width: 365,
        bottom: (theme) => theme.spacing(2),
        left: (theme) => theme.spacing(2)
      }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Type a message"
          inputProps={{ 'aria-label': 'Type a message' }}
          value={chatData}
          onChange={updateChatData}
          onKeyDown={keyPressed}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="attachment" component="label">
          <input type="file" onChange={uploadFile} hidden/>
          <AttachFile />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={updateFinalChatData}>
          <SendRounded />
        </IconButton>
      </Paper>
    </Box>
  </Drawer>
  );
};

export default ChatSection;
