import React, { useEffect } from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import { Mic, MicOff } from "@material-ui/icons";
import { useSelector } from "../../../../store";
import { toggleMicrophone } from "./../../../../utils/webRTCHandler";
import { updateMute } from "./../../../../utils/wss";
import { handleMuteUnmute } from "./../../../../utils/audioRecorder";

const MicButton = () => {
  const { micState, participants, roomId } = useSelector((state) => state.room);

  const handleMicButtonPressed = () => {
    toggleMicrophone();
    updateMute({ roomId });
  };

  useEffect(() => {
    handleMuteUnmute(!micState);
  }, [micState]);

  return (
    <Box
      sx={{
        ml: 1,
      }}
    >
      <Button
        sx={{
          color: "primary.main",
          backgroundColor: "background.paper",
          "&:hover": {
            backgroundColor: "transparent",
            color: "white",
          },
        }}
        onClick={handleMicButtonPressed}
      >
        {!micState ? <MicOff /> : <Mic />}
      </Button>
    </Box>
  );
};

export default MicButton;
