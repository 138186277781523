import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Badge,
} from "@material-ui/core";
import MenuIcon from "../icons/Menu";
import Logo from "./Logo";
import ChatSection from "./room/sections/ChatSection";
import {
  Chat,
  LaptopWindows,
  Mic,
  StickyNote2,
  Videocam,
} from "@material-ui/icons";
import { useSelector } from "../store";
import store from "../store/index";
import { setNewNotification } from "../slices/roomSlice";
import Notes from "./Notes";
import AlertDialog from "./AlertDialog";
import { endCallEvent } from "../utils/callEvent";
import { createSR } from "../api/serviceRequest";
import MicButton from "./room/sections/video/MicButton";
import CameraButton from "./room/sections/video/CameraButton";
import SwitchToScreenShareButton from "./room/sections/video/SwitchToScreenShareButton";
import InviteButton from "./room/sections/video/InviteButton";

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;
  const [open, setOpen] = useState(false);
  const [showNotesDialogue, setShowNotesDialogue] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const {
    newNotification,
    roomId,
    attendeeToken,
    teamMemberId,
    callEnded,
    identity,
    fromUrl,
  } = useSelector((state) => {
    return state.room;
  });

  // window.addEventListener("beforeunload", (ev) => {
  //     // alert('Hi');
  //     ev.preventDefault();
  //     return ev.returnValue = 'Are you sure you want to close?';
  // });

  useEffect(() => {
    store.dispatch(setNewNotification(0));
  }, [open]);

  useEffect(() => {
    if (teamMemberId) {
      setAlertSuccessMessage("Call Ended");
      setShowAlertDialog(true);
      setAlertType("endCall");
    }
  }, [callEnded]);

  const openChatWindow = () => {
    setOpen(true);
  };

  const closeChatWindow = () => {
    setOpen(false);
  };

  const handleEndCall = async () => {
    await endCallEvent();
    setAlertSuccessMessage("Call Ended");
    setShowAlertDialog(true);
  };

  const handleCreateSR = async () => {
    // const notes = localStorage.getItem("notes");
    const roomData = store.getState().room;
    const res = await createSR(
      roomData.roomId,
      roomData.teamMemberId,
      roomData.notes,
      identity
    );

    console.log("createSR - res", res);

    if (res.success === true) {
      setAlertSuccessMessage(res.message + " : " + res.sr_no);
    } else {
      console.log("Create SR err", res.message);
      setAlertSuccessMessage("Something went wrong! Try again");
    }
    setAlertType("createSR");
    setShowAlertDialog(true);
  };

  const closeAlertDialog = () => {
    setShowAlertDialog(false);
    console.log("alertType", alertType);
    if (fromUrl || alertType == "createSR") window.close();
  };

  return (
    <>
      {showNotesDialogue && (
        <Notes
          showNotesDialogue={showNotesDialogue}
          setShowNotesDialogue={setShowNotesDialogue}
        />
      )}

      {showAlertDialog && (
        <AlertDialog
          open={showAlertDialog}
          handleClose={closeAlertDialog}
          successMsg={alertSuccessMessage}
        />
      )}
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "primary.main",
          color: "text.secondary",
        }}
      >
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            sx={{
              display: {
                md: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <RouterLink to="/">
            <Logo
              sx={{
                display: {
                  md: "inline",
                  xs: "none",
                },
                height: 55,
              }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: "center",
              display: {
                md: "flex",
                xs: "none",
              },
            }}
            className="iconnect-nav-buttons"
          >
            <MicButton />
            <CameraButton />
            <SwitchToScreenShareButton />
            {teamMemberId ? <InviteButton /> : ""}
            <Button
              variant="outlined"
              onClick={openChatWindow}
              sx={{
                backgroundColor: "background.paper",
                color: "text.primary",
                ml: 1,
              }}
            >
              <Badge badgeContent={newNotification} color="success">
                <Chat />
              </Badge>
            </Button>

            <Button
              variant="outlined"
              onClick={() => setShowNotesDialogue(true)}
              sx={{
                backgroundColor: "background.paper",
                color: "text.primary",
                ml: 1,
                display: teamMemberId ? "inline-flex" : "none",
              }}
            >
              <StickyNote2 />
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleCreateSR()}
              sx={{
                backgroundColor: "background.paper",
                color: "text.primary",
                ml: 1,
                display: teamMemberId ? "block" : "none",
              }}
            >
              Create SR
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "background.paper",
                color: "error.main",
                ml: 1,
              }}
              onClick={handleEndCall}
            >
              End Call
            </Button>
          </Box>
        </Toolbar>
        <Divider />
        <ChatSection open={open} closeChatWindow={closeChatWindow} />
      </AppBar>
    </>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
