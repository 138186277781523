import React, { useState } from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import { ScreenShare, StopScreenShare } from "@material-ui/icons";
import { useDispatch, useSelector } from "../../../../store";
import {
  setScreenShareStream,
  toggleScreenShare,
} from "../../../../slices/roomSlice";
import * as webRTCHandler from "../../../../utils/webRTCHandler";
import { useEffect } from "react";

const constraints = {
  video: {
    cursor: "always",
  },
  Audio: false,
};

const SwitchToScreenShareButton = () => {
  const { screenShareActive, screenShareStream } = useSelector(
    (state) => state.room
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (screenShareActive) {
      webRTCHandler.toggleScreenShare(screenShareActive, screenShareStream);
    } else {
      webRTCHandler.toggleScreenShare(screenShareActive);
    }
  }, [screenShareActive]);

  const handleScreenShare = async () => {
    try {
      if (!screenShareActive) {
        const stream = await navigator.mediaDevices.getDisplayMedia(
          constraints
        );
        if (stream) {
          stream.getVideoTracks()[0].addEventListener("ended", () => {
            dispatch(toggleScreenShare(false));
            dispatch(setScreenShareStream(null));
          });
          dispatch(setScreenShareStream(stream));
          dispatch(toggleScreenShare(true));
        }
      } else {
        console.log(screenShareStream);
        screenShareStream.getTracks().forEach((track) => track.stop());
        dispatch(toggleScreenShare(false));
        dispatch(setScreenShareStream(null));
      }
    } catch (error) {
      console.log("error while getting screen share stream", error);
    }
  };
  return (
    <Box
      sx={{
        ml: 1,
      }}
    >
      <Button
        sx={{
          color: "primary.main",
          backgroundColor: "background.paper",
          "&:hover": {
            backgroundColor: "transparent",
            color: "white",
          },
        }}
        onClick={handleScreenShare}
      >
        {screenShareActive ? <StopScreenShare /> : <ScreenShare />}
      </Button>
    </Box>
  );
};

export default SwitchToScreenShareButton;
