import React, { useState, useEffect } from "react";
import { Videocam, VideocamOff } from "@material-ui/icons";
import { Box, Button } from "@material-ui/core";
import { useSelector } from "../../../../store";
import { toggleVideo } from "../../../../utils/webRTCHandler";

const CameraButton = () => {
  const { camState } = useSelector((state) => state.room);
  const [hasCamera, setHasCamera] = useState(true);

  const cameraButtonPressed = () => {
    toggleVideo();
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // Filter devices to find video input devices (cameras)
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 0) {
        setHasCamera(true);
      } else {
        setHasCamera(false);
      }
    });
  }, []);

  return (
    <>
      {hasCamera ? (
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            sx={{
              color: "primary.main",
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "transparent",
                color: "white",
              },
            }}
            onClick={cameraButtonPressed}
          >
            {camState ? <Videocam /> : <VideocamOff />}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default CameraButton;
