import io from "socket.io-client";
import {
  setParticipants,
  setRoomId,
  setScreenSharedBy,
  toggleScreenShare,
  setChatHistory,
  setNewNotification,
} from "../slices/roomSlice";
import store from "./../store/index";
import * as webRTCHandler from "./webRTCHandler";
import { endCallEvent } from "./callEvent";
import { stopAudioChunkUploading } from "./audioRecorder";
const SERVER = process.env.REACT_APP_SOCKET_URL;

let socket = null;

export const connectWithSocketIOServer = () => {
  socket = io(SERVER);

  socket.on("connect", () => {
    console.log("Connected with socket.io server", socket.id);
  });

  socket.on("room-id", (data) => {
    console.log("Room Id", data);
    store.dispatch(setRoomId(data));
  });

  socket.on("room-update", (data) => {
    const { connectedUsers, call_id } = data;
    console.log("Room update", data);
    store.dispatch(setParticipants(connectedUsers));
    const screenShareActive = store.getState().room.screenShareActive;
    if (screenShareActive) {
      setTimeout(() => {
        socket.emit("room-screen-share-activity", {
          isScreenSharingActive: true,
          roomId: store.getState().room.roomId,
        });

        const screenShareStrem = store.getState().room.screenShareStream;
        webRTCHandler.toggleScreenShare(screenShareActive, screenShareStrem);
      }, 10000);
    }
  });

  socket.on("connection-init", (data) => {
    const { connectedUserSocketId } = data;
    console.log("Connection init", data);
    webRTCHandler.prepareNewPeerConnection(connectedUserSocketId, true);
  });

  socket.on("connection-prepare", (data) => {
    const { connectedUserSocketId } = data;
    console.log("Connection prepare", data);
    webRTCHandler.prepareNewPeerConnection(connectedUserSocketId, false);

    socket.emit("connection-init", {
      connectedUserSocketId,
    });
  });

  socket.on("connection-signal", (data) => {
    console.log("Connection signal", data);
    webRTCHandler.handleSinalingData(data);
  });

  socket.on("user-disconnected", (data) => {
    console.log("User disconnected", data);
    webRTCHandler.removePeerConnection(data);
  });

  socket.on("room-screen-share-activity", (data) => {
    console.log("Room screen share activity", data);
    const { isScreenSharingActive, socketId, userId } = data;
    store.dispatch(setScreenSharedBy(isScreenSharingActive ? userId : null));
  });

  socket.on("new-message", (data) => {
    console.log("new-message", data);
    const identity = store.getState().room.identity;
    if (identity != data.identity) store.dispatch(setNewNotification(1));
    store.dispatch(setChatHistory(data));
  });

  socket.on("camera-activity", (data) => {
    console.log("camera-activity", data);
    const { isCameraActive, socketId } = data;
    const { participants } = store.getState().room;
    const participant = participants.find((p) => p.socketId === socketId);
    console.log("participant", participant);
    if (participant) {
      const temp = [
        {
          ...participant,
          camera: isCameraActive,
        },
        ...participants.filter((p) => p.socketId !== socketId),
      ];
      store.dispatch(setParticipants(temp));
    }
  });

  socket.on("mute-updated", (data) => {
    stopAudioChunkUploading();
    console.log("mute-updated", data.connectedUsers);
    store.dispatch(setParticipants(data.connectedUsers));
  });

  socket.on("end-call-for-others", (data) => {
    console.log("end-call-for-others", data);
    const roomData = store.getState().room;

    const { callEnded, fromUrl } = roomData;
    if (callEnded === 0) {
      endCallEvent(data.is_agent);
    } else {
      if (data.is_agent && fromUrl) {
        // console.log("bik close initiated3");
        window.close();
      }
    }
  });
};

export const createNewRoom = (data) => {
  console.log("Create new room", data.identity);
  socket.emit("create-new-room", data);
};

export const joinRoom = (roomId, identity, attendee_token) => {
  const data = {
    roomId,
    identity,
    attendee_token,
    caller_id: roomId,
    camera: false,
  };
  socket.emit("join-room", data);
};

export const signalPeerData = (data) => {
  console.log("Signal peer data", data);
  socket.emit("connection-signal", data);
};

export const notifyRoomParticipantsForScreenShare = (isScreenSharingActive) => {
  console.log(
    "Notify room participants for screen share",
    isScreenSharingActive
  );
  const roomId = store.getState().room.roomId;
  socket.emit("room-screen-share-activity", {
    isScreenSharingActive,
    roomId,
  });
};

export const sendMessage = (data) => {
  socket.emit("send-message", data);
};

export const endCall = (data) => {
  socket.emit("end-call", data);
};

export const updateCameraStatus = (isCameraActive) => {
  const data = {
    isCameraActive,
    roomId: store.getState().room.roomId,
  };
  if (socket) {
    socket.emit("camera-activity", data);
  }
};
export const updateMute = (data) => {
  socket.emit("update-mute", data);
};
