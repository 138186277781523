import React from "react";
import { Paper, Box, Link } from "@material-ui/core";
import downloadImg from "../../../images/chatDownload.jpg";
import { useSelector } from "../../../store";

const LoadChatData = (props) => {
  const { identity, chatHistory } = useSelector((state) => {
    return state.room;
  });

  console.log("chat-history-data", chatHistory);

  var chatContent = chatHistory.map((chat, index) =>
    <ChatItem key={index} chatData={chat} currentUser={identity}/>
  );

  return (chatContent);
};

function ChatItem(props) {
  return (
  <Box sx={{
    p: 1,
    m: 1,
    display: "flex",
    justifyContent: props.chatData.userId == props.currentUser? "flex-end" : "flex-start"

  }}>
    <Paper
      elevation={0}
      component="span"
      variant="outlined"
      sx={{
        p: 1,
        backgroundColor: props.chatData.userId != props.currentUser && "#e8f4f8",
        // color: props.chatData.userId != props.currentUser && "#FFFFFF",
        maxWidth: "340px",
        wordWrap: "break-word",
      }} > 
      { props.chatData.file != null ? 
        <MediaMessage chatData={props.chatData} currentUser={props.currentUser}/> : 
        <TextMessage chatData={props.chatData} currentUser={props.currentUser}/>
      }
    </Paper>
  </Box>);
}

function TextMessage(props) {
  return (
    <Box>{ props.chatData.userId == props.currentUser ? "you: " : props.chatData.userId + ": " } 
    { props.chatData.text.includes("http") ? <Link href={props.chatData.text} color="inherit"  target="_blank" rel="noopener">{props.chatData.text}</Link>: props.chatData.text}</Box>
  );
}

function MediaMessage(props) {
  return (
    <Box>
      <Box>
        { props.chatData.userId == props.currentUser ? "you" : props.chatData.userId } : 
      </Box>
      <Link href={props.chatData.s3FileUrl} underline="none" color="inherit" align="center" target="_blank" rel="noopener">
        <Box component="img"
        src={downloadImg}
        sx={{
          height: 100,
          width: "auto"
        }}/>
        <Box align="center">
          { props.chatData.text }
        </Box>
      </Link>
    </Box>
  );
}

export default LoadChatData;
