import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

let db;

const credentials = {
  accessKeyId: "AKIATFRGCPN5RPZ6HOM2",
  secretAccessKey: "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",
};

const makeTX = (storeName, mode) => {
  let tx = db.transaction(storeName, mode);
  tx.onerror = (err) => {
    console.warn(err);
  };
  return tx;
};

export const createIndexedDB = () => {
  let objectStore = null;
  let DBOpenReq = indexedDB.open("ico-test-db3", 1);
  console.log("creating.... React");
  DBOpenReq.addEventListener("error", (err) => {
    console.log("error", err);
  });

  DBOpenReq.addEventListener("success", (e) => {
    db = e.target.result;
    console.log("success", db);
  });
  DBOpenReq.addEventListener("upgradeneeded", (e) => {
    db = e.target.result;
    console.log("Upgrade", db);
    if (!db.objectStoreNames.contains("ico")) {
      console.log("Creating store");
      objectStore = db.createObjectStore("ico", {
        keyPath: "id",
      });
    }
  });
};

var uploadVideoToIndexedDB = (id, blob, type, tag, cid) => {
  let tx = makeTX("ico", "readwrite");
  tx.oncomplete = (e) => {
    console.log(e);
  };
  let store = tx.objectStore("ico");
  let request = store.add({
    id,
    blob,
    type,
    tag,
    cid,
  });
  request.onsuccess = (e) => {
    console.log("Successfully Uploaded to db", e);
    console.log("Close window initiated");
  };
  request.onerror = (err) => {
    console.log("error", err);
  };
};

export const uploadBlobsToS3 = async (
  recordedChunkBlob,
  folderName,
  call_id,
  type,
  format = "webm"
) => {
  try {
    const audioBlob = recordedChunkBlob;
    const timestamp = new Date().getTime();

    console.log(
      `Uploading ${type} in folder ${folderName} for call ID ${call_id}`
    );

    if (false && "serviceWorker" in navigator && "SyncManager" in window) {
      console.log("React generated Audio file", timestamp);
      uploadVideoToIndexedDB(
        timestamp,
        audioBlob,
        "audio/webm",
        "final_audio",
        call_id
      );
      return;
    }

    const audioFileName = new Date().getTime() + "." + format;
    const audioFile = new File([audioBlob], audioFileName, {
      type: `${type}/${format}`,
    });

    const parallelUploads3 = new Upload({
      client: new S3Client({ region: "us-east-2", credentials }),
      params: {
        Bucket: "iconnect-final-recordings",
        Key: `${call_id}/${folderName}/${timestamp}.${format}`,
        Body: audioFile,
        ContentType: `${type}/${format}`,
      },
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      console.log(progress);
    });

    const res = await parallelUploads3.done();
    console.log("Uploaded recorded audio ");
    return res;
  } catch (error) {
    console.log("error", error);
  }
};
