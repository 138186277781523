import store from "../store/index";
import { useSelector } from "../store";
import { setCallEnded } from "../slices/roomSlice";
import { endCall } from "../utils/wss";
import { saveChatUrl } from "../api/chat";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

export const endCallEvent = async (isAgent) => {
  const roomData = store.getState().room;
  const { roomId, attendeeToken, teamMemberId, callEnded, chatHistory } =
    roomData;

  if (isAgent === undefined) {
    isAgent = teamMemberId ? 1 : 0;
  }

  if (callEnded === 0) {
    store.dispatch(setCallEnded());
    if (teamMemberId) {
      console.log("teamMemberId", teamMemberId);
      if (chatHistory.length > 0) {
        console.log("chatHistory-final", chatHistory);
        let chatDataArr = [];
        for (let i = 0; i < chatHistory.length; i++) {
          let tempStruct = {};
          tempStruct.type = "text";
          tempStruct.author = chatHistory[i].identity;
          tempStruct.body = chatHistory[i].file
            ? chatHistory[i].s3FileUrl
            : chatHistory[i].text;
          tempStruct.byOrganization = chatHistory[i].file ? false : true;
          chatDataArr.push(tempStruct);
        }

        console.log("chatDataArr", chatDataArr);
        const chatData = JSON.stringify(chatDataArr);
        const blob = new Blob([chatData], {
          type: "text/plain",
        });
        const fileData = new File([blob], `${roomId}_.txt`);

        console.log("file", fileData);
        const credentials = {
          accessKeyId: "AKIATFRGCPN5RPZ6HOM2",
          secretAccessKey: "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",
        };
        console.log("credentials", credentials);
        const s3UploadedData = new Upload({
          client: new S3Client({ region: "us-east-2", credentials }),
          params: {
            Bucket: "iconnect-chats",
            Key: `${roomId}/chat-${fileData.name}`,
            Body: fileData,
            ACL: "public-read",
          },
        });

        const res = await s3UploadedData.done();
        console.log("chat-upload", res);
        console.log("chat-upload-location", res.Location);
        if (res.Location) {
          const chatUrl = res.Location;
          await saveChatUrl({
            roomId,
            chatUrl,
          });
        } else {
          console.log("chat-upload-err", res);
        }
      }
    }
    const res = endCall({
      caller_id: roomId,
      attendee_token: attendeeToken,
      is_agent: isAgent,
    });
  } else {
    // console.log("bik close initiated1");
    window.close();
  }
};
