import { Box, Button, Drawer } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import React from "react";
import TeamMembers from "./TeamMembers";

const InviteButton = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    console.log("toggleDrawer");
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box
      sx={{
        ml: 1,
      }}
    >
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={() => toggleDrawer("right", true)}
            sx={{
              color: "primary.main",
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "transparent",
                color: "white",
              },
            }}
          >
            <Share />
          </Button>
          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <TeamMembers />
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default InviteButton;
